import BasePlugin from '../BasePlugin'

export default class CreateRecordsForAddressProgramAdjustmentCommand extends BasePlugin {
  async execute () {
    let adjustmentRequestId = this.context.getModel().id
    let grid = this.context.getDashboardComponents()['component_11a9c6e0-2425-41de-8487-362c389105b3'][0] // .$refs.table.$refs.table.data
    let investmentExpensesBudgetsid = [] // grid.map(item => item.id)

    Object.values(grid.$refs.table.selected).forEach(function (item) {
      investmentExpensesBudgetsid.push(item.id)
    })

    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/CreateRecordsForAddressProgramAdjustmentCommand`,
      { 'adjustmentRequestId': adjustmentRequestId, 'investmentExpensesBudgetsId': investmentExpensesBudgetsid },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      this.context.getParentContext().getDashboardComponents()['component_56a48105-0bcc-4b73-b33d-6692d130f537'][0].$refs.table.loadData()
      this.context.$msgbox({
        type: 'info',
        message: 'Расчет дельт поставлен в очередь. По завершении вы получите уведомление'
      })
    })
  }
}
